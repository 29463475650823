import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import "../sass/login.scss";

const Login = () => {
  const [Username, setUsername] = useState("");
  const [Password, setPassword] = useState("")
  const [token, setToken] = useState("");
  const [error, setError] = useState("")

  const loginUrl = process.env.REACT_APP_BASE_URL;
  const navigate = useNavigate();

  const handleUsers = (e) => {
    setUsername(e.target.value)
  }
  const handlePassword = (e) => {
    setPassword(e.target.value)
  }

  const handleLogin = (e) => {
    e.preventDefault();
    axios.post(`${loginUrl}/salesprofirstlogin`, {
      username: Username,
      password: Password
    })
      .then((response) => {
        console.log(response)
        setToken(response.data.token)
      })
      .catch((error) => {
        console.log(error, "error")
        setError(error.response.data.detail)
      })
  }

  useEffect(() => {
    localStorage.setItem("token", token)
  }, [token])

  useEffect(() => {
    if (token) {
      navigate('/information')
    }
  }, [token])
  return (
    <div className='login'>
      <main>
        <form >
          <div className='admin'>
            <img src='admin.jpg' className='admin-pic' alt='admin' />
          </div>
          <div className='item-user'>
            <label>Username:</label>
            <div>
              <input type="text" className='input-user' onChange={handleUsers} />
            </div>
          </div>
          <div className='item-user'>
            <label>Password:</label>
            <div>
              <input type="password" className='input-user' onChange={handlePassword} />
            </div>
          </div>
          <div className='btn-login-center'>
            <button type='submit' className='btn-login' onClick={handleLogin}>LOGIN</button>
          </div>
          {/* <span><a href="#">Forgot Password?</a></span> */}
        </form>
      </main>
      {error ? <p style={{ color: "red" }}>{error}</p> : ""}
    </div>
  )
}

export default Login
