import React, { useState, useEffect } from 'react'
import { useNavigate} from 'react-router-dom';
import { useForm } from "react-hook-form";
import axios from 'axios';
import "../sass/style.scss";

const ValidateForm = () => {
    const [email, setEmail] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [token, setToken] = useState("");

    const URL = process.env.REACT_APP_BASE_URL;
    const { register, handleSubmit, watch, formState: { errors }, reset } = useForm();

    let navigate=useNavigate();

    useEffect(() => {
        let token_check=localStorage.getItem("token");
        if(!token_check){
            navigate("/login")
        }
        else{
            setToken(localStorage.getItem("token"))
        }
    }, []);

    // console.log("http://abc.com ".replace(/(^\w+:|^)\/\//, ''));

    const onSubmit = async (data) => {
        console.log('=>', data)
        // data["access_pass"] = process.env.REACT_APP_ADMIN
        const result= await axios.post(`${URL}/salesproentry`, data, {
            headers: {
                'token': `${token}`
            }
        })
            .then((res) => {
                console.log(res)
            })
            .catch((error) => {
                console.log(error)
                setErrorMessage(error.response.data.detail)
            })
            reset(result);
    }

    const handleEmail = (event) => {
        setEmail(event.target.value)
    }
    return (
        <div className="salespro">
            {errorMessage ? <p style={{ color: "red" }}>{errorMessage}</p> : ""}
            <p>Enter the credentials</p>
            <form onSubmit={handleSubmit(onSubmit)} className="salespro-form">
                <div className='company-info'>
                    <p>Company Information</p>
                    <div className='item-input'>
                        <label>Outlet Name:</label>
                        <input {...register("Outletname", { required: true })} placeholder="Outlet Name" />
                        <div className='handle-error'>
                            {errors.Outletname && <span className='error'>This field is required</span>}
                        </div>
                    </div>
                    <div className='item-input'>
                        <label>Address:</label>
                        <input {...register("Address", { required: true })} placeholder="address" />
                        <div className='handle-error'>
                            {errors.Address && <span className='error'>This field is required</span>}
                        </div>
                    </div>
                    <div className='item-input'>
                        <label>City:</label>
                        <input {...register("City", { required: true })} placeholder="Eg: Dharan" />
                        <div className='handle-error'>
                            {errors.City && <span className='error'>This field is required</span>}
                        </div>
                    </div>
                    <div className='item-input'>
                        <label>Country:</label>
                        <input {...register("Country")} placeholder="Eg: Nepal" />
                    </div>

                    <div className='item-input'>
                        <label>Email:</label>
                        <input {...register("Email", { pattern: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/i })} placeholder="example@gmail.com" onChange={handleEmail} />
                        <div className='handle-error'>{errors.Email && <span className='error'>Incorrect email format</span>}</div>
                    </div>
                    <div className='item-input'>
                        <label>Phone:</label>
                        <input {...register("Phone")} placeholder="Eg: 9840001111" />
                    </div>
                </div>
                <div className='server-info'>
                    <p>Server Information</p>
                    <div className='item-input'>
                        <label>Username:</label>
                        <input {...register("Username", { required: true })} placeholder={email} value={email} />
                        <div className='handle-error'>
                            {/* {errors.Username && <span className='error'>This field is required</span>} */}
                        </div>
                    </div>
                    <div className='item-input'>
                        <label>Password:</label>
                        <input {...register("Password", { required: true }, { pattern: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/i })} placeholder="password" type="password" />
                        <div className='handle-error'>
                            {errors.Password && <span className='error'>This field must contain minimum eight characters, at least one letter and one number</span>}
                        </div>
                    </div>
                    <div>
                        <label>Base URL:</label>
                        <div className='item-input' id='url-margin'>
                            <span>http://</span>
                            <input {...register("BaseURL", { required: true })} placeholder="Base URL" />
                            <div className='handle-error'>
                                {errors.BaseURL && <span className='error'>This field is required</span>}
                            </div>
                        </div>
                    </div>
                    <div className='item-input'>
                        <label>No. of devices:</label>
                        <input {...register("Device_count", { required: true })} placeholder="No. of devices" type="number" />
                        <div className='handle-error'>
                            {errors.Device_count && <span className='error'>This field is required</span>}
                        </div>
                    </div>
                    <div className='submit-form'>
                        <input type="submit" className='btn-submit' />
                    </div>
                </div>
            </form>
        </div>
    )
}

export default ValidateForm
