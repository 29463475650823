import React from 'react'
import "../../sass/deleteModal.scss";

const DeleteModal = ({handleDelete, outlet}) => {
    return (
        <div className="modal fade " id="centerBackdrop" data-backdrop="static" data-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        Are you sure you want to delete the data?
                    </div>
                    <div className="modal-footer">
                        <button type="button" className='btn-delete' onClick={() => handleDelete(outlet)}>Yes</button>
                        <button type="button" className="btn-cancel" data-bs-dismiss="modal" >No</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DeleteModal