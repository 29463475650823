import axios from 'axios';
import React, { useState, useEffect } from 'react';
import "../sass/table.scss";
import Modal from './Modal/modal';
import TokenModal from './Modal/TokenModal';

const Information = ({ table, setTable }) => {
    const pass = process.env.REACT_APP_ADMIN;
    const [deviceID, setDeviceID] = useState("")
    const [tokens, setToken] = useState("")
    const [userToken, setUserToken] = useState("");

    useEffect(() => {
        setUserToken(localStorage.getItem("token"))
    }, []);

    const deleteDeviceID = (id) => {
        const newTable = (table.filter(item => item.DeviceID !== id));
        console.log(newTable);
        setTable(newTable)
        axios.post(`http://salesproportal.silverlinepos.com/salesprotbdelete`, {
            DeviceID: id,
        },
            {
                headers: {
                    'token': `${userToken}`
                }
            })
            .then((response) => {
                console.log(response)

            })
            .catch((error) => {
                console.log(error)
            })
    }
    useEffect(() => {
        getToken();
    }, [table])

    const getToken = () => {
        table.map((item) => (
            setToken(item.Token)
        ))
    }
    const getDeviceID = (id) => {
        setDeviceID(id)
    }
    const handleBlock = (id) => {
        axios.post(`http://salesproportal.silverlinepos.com/salesproblock`, {
            DeviceID: id,
        }, {
            headers: {
                'token': `${userToken}`
            }
        })
            .then((res) => {
                console.log(res)
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const handleUnBlock = (id) => {
        axios.post(`http://salesproportal.silverlinepos.com/salesprounblock`, {
            DeviceID: id,
        }, {
            headers: {
                'token': `${userToken}`
            }
        })
            .then((res) => {
                console.log(res)
            })
            .catch((error) => {
                console.log(error)
            })
    }

    return (
        <div className='table-responsive'>
            <table>
                <thead>
                    <tr>
                        <th scope="col">Device_ID</th>
                        <th scope="col">Token</th>
                        <th scope="col">OTP</th>
                        <th scope="col">OTP Created</th>
                        <th scope="col">Attempts</th>
                        <th scope="col">is_blocked</th>
                        <th scope="col">Delete</th>
                    </tr>
                </thead>
                <tbody>
                    {table.map((items, index) => (
                        <tr key={index}>
                            <td data-label="Device ID">{items.DeviceID}</td>
                            <td data-label="Token"><button type="button" className='btn-view' data-bs-toggle="modal" data-bs-target="#tokenModal">
                                View
                            </button></td>
                            <td data-label="otp">{items.otp}</td>
                            <td data-label="otp_createdAt">{items.otp_createdAt}</td>
                            <td data-label="Attempts">{items.Attempts}</td>
                            <td data-label="is_blocked">{(items.is_blocked === 0) ? <button className='btn-block' onClick={() => handleBlock(items.DeviceID)}>Block</button> : <button className='btn-unblock' onClick={() => handleUnBlock(items.DeviceID)}>UnBlock</button>}</td>
                            <td data-label="Delete"><button type="button" className='btn-delete' data-bs-toggle="modal" data-bs-target="#staticBackdrop" onClick={() => getDeviceID(items.DeviceID)}>
                                Delete
                            </button></td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <Modal deleteDeviceID={deleteDeviceID} deviceID={deviceID} />
            <TokenModal token={tokens} />
        </div>
    )
}

export default Information
