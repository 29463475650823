import Login from './components/login';

function App() {
  return (
    <div className="App">
      <Login/>
    </div>
  );
}

export default App;
