import React from 'react'
import "../../sass/modal.scss";

const Modal = ({deleteDeviceID, deviceID}) => {
    return (
        <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        Do you want to delete this device?
                    </div>
                    <div className="modal-footer">
                        <button type="button" className='btn-delete' onClick={() => deleteDeviceID(deviceID)}>Yes</button>
                        <button type="button" className="btn-cancel" data-bs-dismiss="modal" >No</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Modal
