import React, { useState, useEffect } from 'react'
import "../sass/about.scss";
import axios from 'axios';
import Information from './table';
import DeleteModal from './Modal/deleteModal';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';

const About = () => {
    const URL = process.env.REACT_APP_BASE_URL;
    const [outletName, setOutletName] = useState("");
    const [info, setInfo] = useState("");
    const [table, setTable] = useState([]);
    const [token, setToken] = useState("");

    const navigate = useNavigate();

    useEffect(() => {
        let token_check = localStorage.getItem("token");
        if (!token_check) {
            navigate('/login')
        } else {
            setToken(localStorage.getItem("token"))
        }
    }, [])

    const handleEnter = (e) => {
        e.preventDefault();
        axios.post(`${URL}/salesprofetch`, {
            Outletname: outletName,
        }, {
            headers: {
                'token': `${token}`
            }
        })
            .then((res) => {
                console.log(res)
                setInfo(res.data.info)
                setTable(res.data.table)
            })
            .catch((error) => {
                console.log(error)
                toast(error.response.data.detail)
            })
    }
    const handleChange = (e) => {
        setOutletName(e.target.value)
    }
    const handleDelete = (outlet) => {
        axios.post(`${URL}/salesprodelete`, {
            Outletname: outlet,
        }, {
            headers: {
                'token': `${token}`
            }
        })
            .then((response) => {
                console.log(response)
            })
            .catch((error) => {
                console.log(error)
            })
    }
        ;
    const nextPage = () => {
        navigate('/validate')
    }

    return (
        <div>
            <div className='about-information-flex'>
                <div className='get-information'>
                    <form className='form-entry'>
                        <input type="text" placeholder='outlet name' className='input-outlet' id="outletName" onChange={handleChange} />
                        <div>
                            <button type='submit' className='btn-enter' onClick={handleEnter} >Enter</button>
                            <button type="button" className='btn-delete' data-bs-toggle="modal" data-bs-target="#centerBackdrop">
                                Delete
                            </button>
                        </div>
                    </form>
                    <div className='about'>
                        <div className='get-company-info'>
                            <h4>Company Info</h4>
                            <p>Outlet Name:<span>{info.Outlet_Name}</span></p>
                            <p>Address:<span>{info.Address}</span></p>
                            <p>City:<span>{info.City}</span></p>
                            <p>Country:<span>{info.Country}</span></p>
                            <p>Email:<span>{info.Email}</span></p>
                            <p>Phone:<span>{info.Phone}</span></p>
                        </div>
                        <div className='get-server-info'>
                            <h4>Server Info</h4>
                            <p>Username:<span>{info.User_Name}</span></p>
                            <p>Password:<span>{info.Password}</span></p>
                            <p>Base URL:<span>http://{info.baseURL}</span></p>
                            <p>No. of devices:<span>{info.NoOfDevices}</span></p>
                        </div>
                    </div>
                </div>
                <Information table={table} setTable={setTable} />
                <div className='btn-next-page-center'>
                <button type='submit' className='btn-next-page' onClick={nextPage}>Next</button>
                </div>
            </div>
            <DeleteModal handleDelete={handleDelete} outlet={outletName} />
            <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark"
            />
        </div>
    )
}

export default About
